import React                 from 'react';

import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia   from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';

import Subnavigation from '../components/Subnavigation';

const Kaminholz = (props) => {
  return (
    <>
      <HeaderMedia id="privat-1"/>
      <Subnavigation title="Produkte" menu={[
        {
          'displayName': 'Altholzentsorgung',
          'path': '/altholzentsorgung'
        },
        {
          'displayName': 'Kaminholz',
          'path': '/kaminholz'
        },
        {
          'displayName': 'Streusalz',
          'path': '/streusalz'
        },
      ]}/>
      <Spacer/>
      <Wrapper>
        <Heading subtitle='Sauber, effizient und schnell'>KAMINHOLZ</Heading>
        <Spacer/>
        <h3>Kaminholz</h3>
        <p>Wir bieten Ihnen eine ausgezeichnete Möglichkeit Ihr Kaminholz in unseren Trockenkammern umweltfreundlich zu trocknen. Anstatt der üblichen Lagerung und Lufttrocknung von rund 3 Jahren, ist Ihr Kaminholz innerhalb einer Woche fertig und verwendbar. Dabei erreichen wir einen optimalen Feuchtigkeitsgrad – Pilz- und Schädlingsfrei, sowie einen sehr guten Verbrennungsprozess, nahezu ohne Ruß, Asche und Staub.</p>
        <Spacer />
        <h3>Anlieferungszeiten</h3>
        <p>Montag bis Donnerstag von 8.00 - 14.00 Uhr, Freitag von 8.00 - 12.00 Uhr</p>
        <p>oder nach Terminabsprache</p>
      </Wrapper>
      <Spacer/>
    </>
  )
};

export default Kaminholz;